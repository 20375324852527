@import "./common.scss";

.site-layout-content {
  padding: 24px;
  background: #fff;
}
.ql-container.ql-snow {
  height: 10vw !important;
}
// .google-form-search {
//   .css-1hwfws3 {
//     padding: 0.2% 0.2% 3% 1% !important;
//   }
// }
// .google-form-search-service-area {
//   .css-1hwfws3 {
//     padding: 0.2% 0.2% 1.5% 1% !important;
//   }
// }
.ant-form-item .ant-cascader-picker,
.ant-form-item .ant-select {
  width: 100%;
}
.input-width {
  border-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 50px !important;
  width: 75% !important;
  @media (min-width: 1025px) and(max-width:1280px) {
    width: 65% !important;
  }
  @media (min-width: 1281px) and(max-width:1440px) {
    width: 70% !important;
  }
  @media (max-width: 1024px) {
    width: 60% !important;
  }
}
.add-user-width {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  float: right;
  color: white;
  font-family: $primaryFontFamily;
  font-size: 16px;
  height: 50px;
  width: 25% !important;
  @media (min-width: 1025px) and(max-width:1280px) {
    width: 35% !important;
  }
  @media (min-width: 1281px) and(max-width:1440px) {
    width: 30% !important;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    width: 40% !important;
  }
}
.form-input-profile {
  height: 50px !important;
  border-radius: 5px !important;
  // width: 75%;
  @media (max-width: 767px) {
    width: 60%;
  }
}
.dynamic-delete-button {
  cursor: pointer;
  font-size: normal;
  color: rgb(231, 12, 12);
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: rgb(216, 21, 21);
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.form-input {
  height: 45px !important;
  border-radius: 5px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-input-group .ant-input{
  height: 45px !important;
  // border-radius: 5px !important; 
}
.update-profile-btn {
  height: 40px;
  margin-top: 1vw;
  background: #3045d2 !important;
  border-color: #3045d2 !important;
  border-radius: 3%;
  padding-top: 10px !important;
  text-align: center !important;
  cursor: pointer;
  @media (max-width: 767px) {
    margin-bottom: 5vw;
    margin-top: 3vw;
  }
}
.agent_profile_form .ant-form-item {
  margin-bottom: 24px;
}
.card-body-style {
  margin-top: 1vw;
  @media (min-width: 768px) and(max-width: 2080px) {
    padding: 24px !important;
  }
}
.font-family-style {
  font-family: $primaryFontFamily;
  font-style: normal;
}
.font-medium-size {
  font-weight: 600;
}
.ant-btn-primary,
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  background: #3045d2 !important;
  border-color: #3045d2 !important;
}
.form-input-number {
  height: 45px !important;
  border-radius: 5px !important;
  width: 100% !important;
}
.form-input-number-addonAfter {
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 12px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    // padding: 12px;
  }
}
.header-menu-titles {
  text-align: left;
  font: normal normal medium 16px/28px Poppins;
  font-size: 16px !important ;
  font-weight: medium !important;
  font-family: $primaryFontFamily !important;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.header-menu-titles {
  @media (min-width: 1025px) and (max-width: 1366px) {
    font-size: 14px !important ;
  }
  @media (min-width: 900px) and (max-width: 1024px) {
    font-size: 12px !important ;
  }
  @media (min-width: 768px) and (max-width: 800px) {
    font-size: 10px !important ;
  }
}

.label-titles {
  text-align: left;
  font-size: 15px !important;
  font-family: $primaryFontFamily !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #131419;
  opacity: 1;
  margin-bottom: 5px !important;
}
.btn-updated {
  background-color: #0bc43c !important;
  color: #ffffff !important;
}
@media (min-width: 1441px) and (max-width: 3000px) {
  .input-number-myprofile {
    width: 31.5vw !important;
  }
}
@media (max-width: 1440px) {
  .input-number-myprofile {
    width: 31vw !important;
  }
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 0px !important;
  height: 100%;
  background: #fff;
  border-left: 0px !important;
  border-radius: 0px !important;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
