/* Fonts */
@font-face {
  font-family: opensansbold;
  src: url(../Fonts/OpenSans-Bold.ttf);
}
@font-face {
  font-family: opensanslight;
  src: url(../Fonts/OpenSans-Light.ttf);
}
@font-face {
  font-family: opensansregular;
  src: url(../Fonts/OpenSans-Regular.ttf);
}
@font-face {
  font-family: opensanssemibold;
  src: url(../Fonts/OpenSans-SemiBold.ttf);
}

/* Fonts */

/* FONT-FAMILY */
$primaryFontFamily: "Poppins", sans-serif;

/* Colors*/
$white: #ffffff;
$grey1: #f4f4f6;
$grey2: #c3c5d1;
$grey3: #767c8e;
$grey4: #dfe0e4;
$black: #000;

/* Colors*/
