@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

@import "common.scss";

.sidemenu {
  width: 17%;
  background-color: white;
  height: 100vh;
  box-shadow: 0px 0px 10px #e4e1e1;
  float: left;
}
.admin {
  height: 100vh;
  background-color: #f9fafb !important;
}
.menuitem {
  font-size: 15px;
  font-family: opensanssemibold;
  padding-top: 15px;
  color: #2a477c;
}
.view-user-details {
  margin: 20px 0;
  margin-bottom: 100px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(229, 226, 226, 0.05);
  border-radius: 4px;
}
.menuitemsub {
  font-size: 15px;
  font-family: opensanssemibold;
  padding-top: 15px;
  color: #2a477c;
  padding-left: 70px !important;
}
.ant-layout-header {
  padding: 0px;
  background: none;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: none;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: none;
}
// .ant-menu-submenu-open {
//   // .menuitem {
//   //   color: #c24e3f !important;
//   // }
//   // .ant-menu-submenu-arrow {
//   //   color: #c24e3f !important;
//   // }
//   .ant-menu-submenu-inline
//     > .ant-menu-submenu-title:hover
//     .ant-menu-submenu-arrow::before {
//     background: -webkit-gradient(
//       linear,
//       left top,
//       right top,
//       from(#c24e3f),
//       to(#c24e3f)
//     );
//   }
//   .ant-menu-submenu-inline
//     > .ant-menu-submenu-title:hover
//     .ant-menu-submenu-arrow::after {
//     background: -webkit-gradient(
//       linear,
//       left top,
//       right top,
//       from(#c24e3f),
//       to(#c24e3f)
//     );
//   }
// }
.ant-menu-submenu-arrow {
  color: #2a477c;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #2a477c;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none;
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2a477c),
    to(#2a477c)
  );
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2a477c),
    to(#2a477c)
  );
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white !important;
}
.ant-menu-horizontal {
  background-color: #f9fafb;
}
.headermenu {
  color: white !important;
  font-size: 15px;
  font-family: opensansbold;
}
.admin-header-menu {
  .ant-menu-horizontal > .ant-menu-item-selected {
    color: #c24e3f !important;
    border-bottom: 2px solid #c24e3f;
  }
  .ant-menu-horizontal > .ant-menu-item-selected > a {
    color: #c24e3f !important;
  }
  .ant-menu-horizontal > .ant-menu-item > a {
    color: #2a477c;
  }
  .ant-menu-horizontal > .ant-menu-item:hover {
    color: #c24e3f !important;
    border-bottom: 2px solid #c24e3f;
  }
  .ant-menu-horizontal > .ant-menu-item > a:hover {
    color: #c24e3f !important;
  }
}
.ant-select-selection--single {
  height: 40px !important;
}
.registration {
  .ant-input {
    border: none;
  }
  .ant-input:focus {
    box-shadow: none;
  }
  .ant-dropdown-link {
    background-color: white;
    padding: 10px;
    margin-left: 20px;
    color: #2a477c;
    font-style: 14px;
    font-family: opensansregular;
  }
  // .ant-select-arrow{
  //     right:7px;
  //     font-size:10px;
  //     color:white;
  // }
  .ant-table-tbody > tr > td {
    padding: 5px 10px;
  }
  .ant-table-thead {
    margin-bottom: 20px;
  }
  .ant-table-tbody {
    background-color: white;
  }
  .ant-table table {
    margin-top: 15px;
  }
  .ant-table-row .ant-table-row-level-0:hover {
    background-color: white !important;
  }
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding-left: 10px !important;
}
.registration-add {
  .ant-table-tbody > tr > td {
    padding: 3px 10px !important;
  }
}
.registration-cont {
  .ant-table-tbody > tr > td {
    padding: 10px 10px !important;
  }
}

.registrationtext {
  color: black;
}
.search {
  display: inline-block;
}
.ant-tabs-nav .ant-tabs-tab {
  margin: 0px;
  padding: 12px 7px;
}
.ant-tabs-ink-bar {
  background-color: #c24e3f;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #c24e3f;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #c24e3f;
}
.detailsheading {
  color: #2a477c;
  font-family: opensansregular;
  font-size: 14px;
  margin-bottom: 5px;
}
.detailstext {
  color: black;
  font-family: opensanssemibold;
  font-size: 16px;
}
.detailsmain {
  color: #2e2e43;
  font-size: 18px;
  font-family: opensanssemibold;
}
.checkall {
  float: left;
  background-color: #2a477c;
  color: white;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  font-family: opensansregular;
  font-size: 14px;
}
.logindiv {
  width: 500px;
  display: flex;

  flex-wrap: wrap;
  vertical-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: auto;
}
// .ant-form {
//   width: 350px;
// }
.login-form {
  font-family: opensansregular;
}
.ant-input:hover {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  box-shadow: none;
}
.ant-form-item {
  margin-bottom: 5px;
}

.ant-icon {
  font-size: 60px;
  color: "lightgray";
}

.main-title {
  text-align: center;
  font-size: 28px;
  letter-spacing: 1.5px;
  margin: 25px 0px 0px;
}

.sub-title {
  color: "#777";
  text-align: center;
  font-size: 14px;
  letter-spacing: 1.5px;
}

.mid-title {
  color: "#777";
  text-align: center;
  font-size: 14px;
  letter-spacing: 1.5px;
  margin-top: 15px;
}

.result-small {
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
}

.result-big {
  text-align: center;
  font-size: 40px;
  letter-spacing: 2px;
}

.mob-card {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background: #ecf0f3;
  box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #ffffff;
}

.tab-card {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #ecf0f3;
  box-shadow: 3px 3px 5px #cbced1, -10px -10px 15px #ecf0f3;
}

.mob-logo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0px 0px 2px #5f5f5f, 0px 0px 0px 5px #ecf0f3, 8px 8px 15px #a7aaaf,
    -8px -8px 15px #ffffff;
}

.mob-input {
  border-radius: 5px;
  box-shadow: inset 4px 4px 4px #cbced1, inset -4px -4px 4px #ffffff;
}

.mob-button {
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  box-shadow: 3px 3px 8px #b1b1b1, -3px -3px 8px #ffffff;
}

.date_admin .ant-calendar-picker-input.ant-input {
  height: 45px !important;
}

.select_height .ant-select-selection--multiple {
  height: 45px !important;
}
.ant-card-hoverable {
  @media (max-width: 767px) {
    border-color: transparent !important;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09) !important;
  }
}
.home-card-space-bw {
  height: 100%;
  width: 100%;
  background-color: white;
  box-shadow: 0vw 0.2vw 0.4vw #d4d8f1fa;
  border-radius: 5px;
  margin-top: 0.45vw;
  margin-bottom: 0.45vw;

  @media (min-width: 100px) and(max-width:499px) {
    margin-top: 2.45vw;
    margin-bottom: 2.45vw;
  }
  @media (min-width: 500px) and(max-width:992px) {
    margin-top: 1.45vw;
    margin-bottom: 1.45vw;
  }
}

.dashboard-icons{
  color: #000;
  font-size: 80px !important;
  // @media (min-width:1024px) and (max-width: 1440px) {
  //   font-size: 36px !important;
  // }
}
