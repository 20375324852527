@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

@import "common.scss";

.sidemenu {
  width: 17%;
  background-color: white;
  height: 100vh;
  box-shadow: 0px 0px 10px #e4e1e1;
  float: left;
}
.admin {
  height: 100vh;
  background-color: #f9fafb !important;
}
.menuitem {
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
  padding-top: 15px;
}
.icon_dashboard {
  margin-right: 15px;
  color: #fff;
  font-size: 20px;
}
.menuitemsub {
  font-size: 15px;
  font-family: opensanssemibold;
  padding-top: 15px;
  color: #2a477c;
  padding-left: 70px !important;
}
.sub-menuitem {
  font-size: 15px;
  font-family: opensanssemibold;
  padding-top: 10px;
  color: #fff;
  padding-left: 10px !important;
}
.sub-item-contact {
  padding-left: 60px !important;
}

.ant-layout-header {
  padding: 0px;
  background: none;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: none;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: none;
}
.ant-menu-submenu-open {
  .ant-menu-submenu-arrow {
    color: #c24e3f !important;
  }
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::before {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#c24e3f),
      to(#c24e3f)
    );
  }
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::after {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#c24e3f),
      to(#c24e3f)
    );
  }
}
.ant-menu-submenu-arrow {
  color: #2a477c;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #2a477c;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none;
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2a477c),
    to(#2a477c)
  );
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2a477c),
    to(#2a477c)
  );
}
// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background-color: white !important;
// }
// .ant-menu-horizontal {
//   background-color: #f9fafb;
// }
.headermenu {
  // color: white !important;
  font-size: 15px;
  font-family: opensansbold;
}
.admin-header-menu {
  .ant-menu-horizontal > .ant-menu-item-selected {
    color: #c24e3f !important;
    border-bottom: 2px solid #c24e3f;
  }
  .ant-menu-horizontal > .ant-menu-item-selected > a {
    color: #c24e3f !important;
  }
  .ant-menu-horizontal > .ant-menu-item > a {
    color: #2a477c;
  }
  .ant-menu-horizontal > .ant-menu-item:hover {
    color: #c24e3f !important;
    border-bottom: 2px solid #c24e3f;
  }
  .ant-menu-horizontal > .ant-menu-item > a:hover {
    color: #c24e3f !important;
  }
}
.ant-select-selection--single {
  height: 40px !important;
}
.registration {
  .ant-input {
    border: none;
  }
  .ant-input:focus {
    box-shadow: none;
  }
  .ant-dropdown-link {
    background-color: white;
    padding: 10px;
    margin-left: 20px;
    color: #2a477c;
    font-style: 14px;
    font-family: opensansregular;
  }
  // .ant-select-arrow{
  //     right:7px;
  //     font-size:10px;
  //     color:white;
  // }
  .ant-table-tbody > tr > td {
    padding: 5px 10px;
  }
  .ant-table-thead {
    margin-bottom: 20px;
  }
  .ant-table-tbody {
    background-color: white;
  }
  .ant-table table {
    margin-top: 15px;
  }
  .ant-table-row .ant-table-row-level-0:hover {
    background-color: white !important;
  }
}
@media (min-width: 767px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 10px 10px 10px 24px !important;
  }
}
.ant-table-thead > tr > th {
  background: #f9f9fb !important;
}
.registration-add {
  .ant-table-tbody > tr > td {
    padding: 3px 10px !important;
  }
}

.registrationtext {
  color: black;
}
.search {
  display: inline-block;
}
.ant-tabs-nav .ant-tabs-tab {
  margin: 0px;
  padding: 12px 7px;
}
.ant-tabs-ink-bar {
  background-color: #c24e3f;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #c24e3f;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #c24e3f;
}
.detailsheading {
  color: #2a477c;
  font-family: opensansregular;
  font-size: 14px;
  margin-bottom: 5px;
}
.detailstext {
  color: black;
  font-family: opensanssemibold;
  font-size: 16px;
}
.detailsmain {
  color: #2e2e43;
  font-size: 18px;
  font-family: opensanssemibold;
}
.checkall {
  float: left;
  background-color: #2a477c;
  color: white;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  font-family: opensansregular;
  font-size: 14px;
}
.logindiv {
  width: 500px;
  display: flex;

  flex-wrap: wrap;
  vertical-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: auto;
}
// .ant-form {
//   width: 350px;
// }
.login-form {
  font-family: opensansregular;
}
.ant-input:hover {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  box-shadow: none;
}
.ant-form-item {
  margin-bottom: 5px;
}

.ant-icon {
  font-size: 60px;
  color: "lightgray";
}

.main-title {
  text-align: center;
  font-size: 28px;
  letter-spacing: 1.5px;
  margin: 25px 0px 0px;
}

.sub-title {
  color: "#777";
  text-align: center;
  font-size: 14px;
  letter-spacing: 1.5px;
}


.result-small {
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
}

.result-big {
  text-align: center;
  font-size: 40px;
  letter-spacing: 2px;
}

.mob-card {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background: #ecf0f3;
  box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #ffffff;
}

.tab-card {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #ecf0f3;
  box-shadow: 3px 3px 5px #cbced1, -10px -10px 15px #ecf0f3;
}

.mob-logo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0px 0px 2px #5f5f5f, 0px 0px 0px 5px #ecf0f3, 8px 8px 15px #a7aaaf,
    -8px -8px 15px #ffffff;
}

.mob-input {
  border-radius: 5px;
  box-shadow: inset 4px 4px 4px #cbced1, inset -4px -4px 4px #ffffff;
}

.mob-button {
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  box-shadow: 3px 3px 8px #b1b1b1, -3px -3px 8px #ffffff;
}

.done {
  text-align: left;
  text-decoration: line-through;
  font-size: normal normal medium 18px/27px Poppins;
  letter-spacing: 0px;
  color: #131419;
  opacity: 1;
}

#app {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  transition: all 0.2s;
}

// .ant-checkbox-inner {
//   .ant-checkbox-checked {
//     background-color: #10bc1d !important;
//     // width: 20px !important;
//     // height: 20px !important;
//   }
//   border-radius: 50px;
//   background-color: #fff !important;
// }
// // .checkbox-Todo1 {
// //   border-radius: 50px;
// //   border: none !important;
// //   background-color: #fff !important;
// // }
.checkbox-Todo .ant-checkbox-inner .checkbox-Todo1 {
  border-radius: 50px !important;
}
.checkbox-Todo .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #10bc1d !important;
  border-color: #10bc1d !important;
}
.checkbox-Todo1
  .ant-checkbox-wrapper:hover
  .ant-checkbox-inner
  .ant-checkbox:hover
  .ant-checkbox-inner
  .ant-checkbox-input:focus
  + .ant-checkbox-inner {
  border-color: #99a2c3 !important;
}
.checkbox-Todo .checkbox-Todo1 {
  line-height: 0;
  cursor: pointer;
}

.card_virtual.ant-card-bordered {
  border-radius: 8px !important;
  box-shadow: 0px 10px 20px #0000000d;
  font-size: 22px;
}
.card_virtual .ant-card-head-title {
  color: #232323;
  opacity: 1;
  letter-spacing: 0px;
  padding: 10px 0px 10px;
  font-size: 22px;
  font-family: Optimistic Text, Helvetica, Helvetica Neue, Arial, sans-serif;
  text-align: left;
}

.card_hover.ant-card-bordered {
  border-radius: 8px !important;
  box-shadow: 0px 10px 20px #0000000d;
}
.card_hover .ant-card-head-title {
  color: #232323;
  opacity: 1;
  letter-spacing: 0px;
  padding: 10px 0px 10px;
  font-size: 22px;
  font-family: Optimistic Text, Helvetica, Helvetica Neue, Arial, sans-serif;
  text-align: left;
}

.ant-table-pagination.ant-pagination {
  margin: 16px !important;
}
.card_virtual .iLquPE {
  display: none;
}
.card_virtual .cJeRRR {
  display: none;
}
// .card_virtual .galrjd {
//   background-color: #000;
// }
.card_virtual .lgksPe {
  display: none;
}
.card_virtual .bkVSTc {
  display: none;
}
.card_virtual .tXlBS {
  display: block;
}
.table_conatcts .ant-table-column-sorters {
  padding: 5px 5px !important;
}
.card_button {
  border-radius: 100px;
  width: 85px;
  height: 35px;
  cursor: pointer;
  color: #3045d2;
}

.virtual_button_text {
  font-size: 15px;
  font-family: $primaryFontFamily;
  letter-spacing: 0px;
  opacity: 1;
}
.calender_view {
  font-size: 16px;
  font-family: $primaryFontFamily;
  letter-spacing: 0px;
  opacity: 1;
}
.react-calendar {
  width: 100% !important;
  border: none !important;
  margin-top: -45px !important;
}
.react-calendar button {
  color: #000;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
.react-calendar__tile--active {
  color: white !important;
}
.react-calendar__month-view__weekdays {
  text-decoration: underline !important;
  color: #99a2c3;
  font-size: 13px !important;
}
.react-calendar__navigation__prev2-button {
  margin-left: 35px !important;
}
.weekView .react-calendar__month-view__days {
  display: none !important;
}
.virtual_button {
  width: 75px;
  text-align: left;
  border: none;
}
.metting_tiltle {
  font-size: 16px;
  font-family: $primaryFontFamily;
  letter-spacing: 0px;
  opacity: 1;
  color: "#131419";
  font-weight: 600;
}
.metting_subtext {
  font-size: 14px;
  font-family: $primaryFontFamily;
  letter-spacing: 0px;
  opacity: 1;
  color: #99a2c3;
  margin-left: 8px;
}
.dot_metting {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 10px;
}
.loads-select {
  .ant-select-selection--single {
    position: relative;
    height: 40px !important;
    cursor: pointer;
  }
  .ant-select-selection-item {
    margin-top: 3px !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px !important;
    border-radius: 5px !important;
  }
  .ant-select-selection__rendered {
    position: relative;
    display: block;
    margin-right: 11px;
    margin-left: 11px;
    line-height: 40px;
  }
}
.height_form {
  height: 40px !important;
  border-radius: 5px !important;
}
.modal_description {
  color: #8d8d8d;
  font-weight: 400;
  font-size: 13px;
  font-family: $primaryFontFamily;
}

.row-margin-table {
  margin-bottom: 10px;
}
.col-tilte {
  font-size: 14px;
  color: black;
  font-weight: 500;
}
.col-desc {
  margin-left: 10px;
}
.mobile_view_margin_Top {
  @media (max-width: 767px) {
    margin-top: 3vw;
  }
}

.traings-title {
  text-align: left;
  font-size: 18px;
  font-family: $primaryFontFamily;
  font-weight: 600;
  letter-spacing: 0px;
  color: #131419;
  text-transform: capitalize;
  opacity: 1;
  // margin-left: 15px;
  margin-bottom: 20px;
}

.upcoming_train_title {
  font-size: 16px;
  font-family: $primaryFontFamily;
  font-weight: 600;
  letter-spacing: 0px;
  color: #131419;
  text-transform: capitalize;
  opacity: 1;
  // margin-left: 15px;
  margin-bottom: 20px;
}
.training-filter-style {
  width: 40px;
  height: 40px;
  background: #99a2c3;
  border-radius: 50%;
}

.form-input-training {
  height: 40px !important;
  border-radius: 5px !important;
  margin-bottom: 5px;
}
.form-input-training .ant-select-selector {
  height: 40px !important;
  border-radius: 5px !important;
}
.form-input-training-description {
  height: 145px !important;
  border-radius: 5px !important;
  margin-bottom: 5px;
}
.form-input-training .ant-select-selection-placeholder {
  font-weight: 400 !important;
  font-size: 15px;
  font-family: "Poppins";
  margin-left: 15px;
}
.form-input-training.ant-input:placeholder-shown {
  font-weight: 400 !important;
  font-size: 15px;
  color: #888888;
  font-family: "Poppins";
}
.form-input-training-description.ant-input:placeholder-shown {
  font-weight: 400 !important;
  font-size: 15px;
  color: #888888;
  font-family: "Poppins";
  padding-left: 25px;
}
.duration-training {
  width: 90px;
  height: 40px;
  margin-left: 1vw;
  border-radius: 5px;
}
.home-card-space-bw-trainings {
  height: 100%;
  width: 100%;
  background-color: white;
  box-shadow: 0vw 0.2vw 0.4vw #d4d8f1fa;
  border-radius: 5px;

  @media (min-width: 100px) and(max-width:499px) {
    margin-top: 2.45vw;
    margin-bottom: 2.45vw;
  }
  @media (min-width: 500px) and(max-width:992px) {
    margin-top: 1.45vw;
    margin-bottom: 1.45vw;
  }
}
.searchHomes-titles-trainigs {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: $black;

  @media (min-width: 1366px) and (max-width: 1600px) {
    font-size: 1.47vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    font-size: 1.6vw;
    margin-bottom: 10px !important;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 2vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 3vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    font-size: 2.7vw !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 100px) and (max-width: 499px) {
    font-size: 4vw !important;
    margin-bottom: 10px !important;
  }
}
.todo_button {
  height: 40px;
  border: 1px solid #3045d2;
  border-radius: 5px;
}
.btn-color {
  color: #3045d2 !important;
}
.todo_title {
  font-weight: 500;
  line-height: 2;
  font-size: 20px;
}
.col_styling {
  color: #99a2c3 !important;
}
.table_desc {
  color: #131419 !important;
  font-weight: 500 !important;
}

.ant-table-row .ant-table-row-level-0 {
  box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #ffffff !important;
}
.table_crm .ant-table-thead > tr > th {
  background-color: #f0f2f5 !important;
  height: 45px !important;
}
.todo_collapse .ant-collapse-header {
  background: #2d63dd !important;
  font-weight: 500;
  color: #fff !important;
  border: none !important;
  z-index: 1;
  margin-bottom: -46px !important;
}
.todo_collapse_goal .ant-collapse-header {
  background: #643889 !important;
  font-weight: 500;
  color: #fff !important;
  border: none !important;
  z-index: 1;
  margin-bottom: -46px !important;
}
.todo_collapse_done .ant-collapse-header {
  background: #99a2c3 !important;
  font-weight: 500;
  color: #fff !important;
  border: none !important;
  z-index: 1;
  margin-bottom: -46px !important;
}

.ant-collapse-header {
  width: fit-content !important;
}
.todo_collapse,
.todo_collapse_goal,
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.ant-table-selection {
  display: none !important;
}
.todo_collapse,
.todo_collapse_goal,
.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
  margin-bottom: 2vw !important;
}
.todo_collapse,
.todo_collapse_goal,
.ant-collapse-content {
  border-top: none !important;
}

.total_budget {
  font-size: 16px;
  font-family: "Poppins";
  letter-spacing: 0px;
  color: #99a2c3;
  opacity: 1;
}

.total_amount {
  font-family: "Poppins";
  text-transform: capitalize;
  opacity: 1;
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0px;
}

.time-picker .rc-time-picker-input {
  height: 40px;
}
.rc-time-picker-clear-icon:after {
  margin-top: 5px;
  font-size: 16px !important;
}

.select_height,
.ant-select-selector {
  height: 40px !important;
  border-radius: 5px;
}

.form-input-height {
  height: 40px !important;
  border-radius: 5px;
}

.virtual_modal .ant-modal-title {
  color: "#000000";
  font-size: 22px !important;
  font-family: $primaryFontFamily;
  font-weight: 500;
}
