@import "common.scss";

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.view-lead-screen {
  color: #000;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  padding: 5px;
}

.view-lead-lable {
  text-align: left;
  font-size: 15px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  letter-spacing: 0;
  color: #131419;
}
