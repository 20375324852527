body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.admin-login-form {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-image: linear-gradient(180deg, #f89a2c, #fc4e1a); */
}

.login-fields {
  margin-top: 20px;
  width: 415px;
  height: fit-content;
  padding: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 10px;
}

.menuitem {
  margin-left: 30px;
  color: #ffffff !important;
}

.ant-menu-item.ant-menu-item-selected {
  margin-left: 10px;
  color: #001528 !important;
}

.ant-menu-item.ant-menu-item-selected .menuitem {
  color: #001528 !important;
}

.ant-layout-sider-collapsed {
  flex: 0 0 90px !important;
  max-width: 90px !important;
  width: 90px !important;
  min-width: 90px !important;
}

.ant-layout-sider-trigger {
  min-width: 90px !important;
}

.borderTable {
  border: 1px solid #dddddd;
  padding: 8px;
  width: 25%;
}
